import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex mt-3">
        <div className="container flex flex-col justify-center text-gray-700">
          <div className="max-w-md">
            <div className="text-5xl font-dark font-bold">404</div>
            <p className="text-2xl md:text-3xl font-light leading-normal">
              Oh, you have broken the internet
            </p>
            <p className="mt-2 mb-8">
              The content you are looking for was not found, and I did not
              implement a search functionality in this simple blog ..... so
              figure out yourself what you were looking for!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
